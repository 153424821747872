import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"
const timeout = 400
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}
const getTransitionStylesAfter = {
  exiting: {
    display: `none`,
  },
  exited: {
    display: `none`,
    position: `fixed`,
    zIndex: `10000`,
    top: `0`,
    left: `0`,
    backgroundColor: `#837AE9`,
    //backgroundColor: `red`,
    height: `100vh`,
    width: `200vw`,
    transition: `transform ${timeout}ms ease-in-out`,
    transform: `translateX(-100%)`,
  },
  entering: {
    display: `none`,
    position: `fixed`,
    zIndex: `10000`,
    top: `0`,
    left: `0`,
    //backgroundColor: `#837AE9`,
    backgroundColor: `blue`,
    height: `100vh`,
    width: `200vw`,
    transition: `transform ${timeout}ms ease-in-out`,
    transform: `translateX(-50%)`,
  },
  entered: {
    display: `none`,
    position: `fixed`,
    zIndex: `10000`,
    top: `0`,
    left: `0`,
    //backgroundColor: `#837AE9`,
    backgroundColor: `red`,
    height: `100vh`,
    width: `200vw`,
    transition: `transform ${timeout}ms ease-in-out`,
    transform: `translateX(50%)`,
  },
}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <div>
              <div
                style={{
                  ...getTransitionStylesAfter[status],
                }}
              >
              </div>
              <div
                style={{
                  ...getTransitionStyles[status],
                }}
              >
                {children}
              </div>
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}
export default Transition
