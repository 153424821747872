import styled, { createGlobalStyle, keyframes } from "styled-components";
import { Link } from "gatsby"

const fadeInUp = keyframes`
 0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`
export const GlobalStyle = createGlobalStyle`
  :root {
    --main: #00A65A;
    --gray: #f4f4f4;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    scroll-behavior: smooth;
  }
  body, input, button {
    font: 18px 'Mukta', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  .fadeInUp {
    animation-name: ${fadeInUp} !important;
  }
  [data-sal=slide-up] {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
   }
`

export const GlobalContainer = styled.div`
  max-width: 1540px;
  padding: 0 32px;
  margin: 0 auto;
  @media (max-width: 1600px) {
    max-width: 1200px;
   }
  @media (max-width: 600px) {
    padding: 0 16px;
   }
  @media (max-width: 450px) {
    padding: 0 8px;
   }
`

export const GlobalSection = styled.section`
  padding: ${props => props.noPadding ? "0" : "120px 0" };
  background: ${props => props.gray ? "var(--gray)" : "#fff" };
  background: ${props => props.bg ? props.bg : "#fff" };
  @media (max-width: 1000px) {
    padding: ${props => props.noPadding ? "0" : "60px 0 120px" };
  }
`
export const GlobalFlex = styled.section`
  display: flex;
  flex-direction: ${props => props.column ? "column" : "row" };
`

export const GlobalSectionHeader = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 100px;
  p {
    margin-top: 10px;
    font-weight: 300;
    font-size: 36px;
    line-height: 1.3em;
    @media(max-width: 720px) {
      font-size: 26px;
    }
  }
  h2 {
    color: var(--main);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
  }
`
export const Button = styled(Link)`
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  padding: 18px 56px;
  min-width: 245px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  border: 0;
  background-clip: border-box !important;
  transition: background 0.3s, color 0.3s !important;
  color: var(--black);
  transform: scale(1);
  background-color: var(--main);
  &:hover {
    background-color: #008032;
    color: #fff;
  }
`
export const Anchor = styled.a`
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  padding: 18px 56px;
  min-width: 245px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  border: 0;
  background-clip: border-box !important;
  transition: background 0.3s, color 0.3s !important;
  color: var(--black);
  transform: scale(1);
  background-color: var(--main);
  &:hover {
    background-color: #008032;
    color: #fff;
  }
`

export const DisabledAnchor = styled.a`
  pointer-events: none;
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  padding: 18px 56px;
  min-width: 245px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  border: 0;
  background-clip: border-box !important;
  transition: background 0.3s, color 0.3s !important;
  color: var(--black);
  transform: scale(1);
  background-color: rgba(0,0,0,0.2); 
  &:hover {
    background-color: #008032;
    color: #fff;
  }
`

export const ButtonPlain = styled(Link)`
  background-color: transparent;
  color: var(--main);
  padding: 0;
  font-weight: 600;
  min-width: auto;
`
export const ButtonOutline = styled(Link)`
  display: inline-block;
  padding: 6px 30px;
  color: #fff;
  border-radius: 30px;
  border: 2px solid rgba(255,255,255,.3);
  transition: background-color .2s;
  &:hover {
    background-color: rgba(255,255,255,.3);
  }
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
