import React from "react"
import Img from 'gatsby-image'
import { graphql, StaticQuery } from "gatsby"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              publicURL
              extension
              childImageSharp {
                sizes(maxWidth: 2560) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }


      const childImageSharp = image.node.childImageSharp;
      const extension = image.node.extension;

      // svg support
      if (!childImageSharp && extension === 'svg') {
        return (
          <LazyLoadImage
            src={image.node.publicURL}
            alt={props.alt} />
        )
      } else {
        const imageSizes = image.node.childImageSharp.sizes;
        return (
          <Img
            alt={props.alt}
            sizes={imageSizes}
          />
        );
      }
    }}
  />
)
export default Image
