import { Link } from "gatsby"
import React, { Component } from "react"

import { GlobalContainer } from "./GlobalStyle"
import styled from "styled-components"

import logo from "../images/logo.png"
import facebookIcon from "../images/icons/facebook.svg"
import linkedinIcon from "../images/icons/linkedin.svg"
import instagramIcon from "../images/icons/instagram.svg"
import coworkImg from "../images/icons/cowork.svg"
import virtualImg from "../images/icons/virtual.svg"
import officeImg from "../images/icons/office.svg"
import lectureImg from "../images/icons/lecture.svg"
import { withTranslation } from 'react-i18next';
import i18next from "i18next"

const StyledHeader = styled.nav`
  width: 100%;
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @media (max-height: 1200px) {
    padding-top: 10px;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    //box-shadow: 0 0 60px 70px rgba(0,0,0,0.7);
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
  }
`
const StyledNav = styled.ul`
  display: flex;
  align-items: center;
  padding-top: 30px;
  @media (max-width: 450px) {
    padding-top: 15px;
  }
  .desktop-nav {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  li:not(:first-of-type) {
    padding: 0 20px;
    position: relative;
    @media (max-height: 1200px) {
      padding: 0 15px;
    }
    a {
      color: #fff;
      font-size: 16px;
      transition: 0.3s;
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 2px;
        width: 0;
        background-color: #9e96fa;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s;
      }
      &.active {
        &:after {
          width: 30px;
        }
      }
    }
  }
  li:last-of-type {
    padding-right: 0;
  }
`
const StyledLogo = styled.li`
  flex: 1;
  position: relative;
  z-index: 200;
  display: flex;
  img {
    width: 130px;
    height: 48px;
    @media (max-width: 1400px) {
      width: 110px;
      height: 41px;
    }
    @media (max-width: 1400px) {
      width: 110px;
      height: 41px;
    }
    @media (max-width: 450px) {
      width: 90px;
      height: 34px;
      position: relative;
      top: 5px;
    }
  }
`

const StyledButton = styled(Link)`
  display: inline-block;
  padding: 6px 30px;
  color: #fff;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  transition: background-color 0.2s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`
const StyledNavDropdown = styled.div`
  background-color: #fff;
  padding: 25px;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0) rotateX(-10deg);
  transform-origin: top;
  border-radius: 4px;
  width: 360px;
  display: flex;
  pointer-events: none;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s, transform 0.3s;
  margin-top: 30px;
  &:before {
    content: "";
    display: block;
    height: 30px;
    width: 100px;
    left: calc(50% - 50px);
    position: absolute;
    opacity: 0;
    top: -20px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: #fff;
    left: calc(50% - 8px);
    top: -6px;
    border-radius: 4px;
    transform: rotate(45deg);
  }
  a {
    display: flex;
    align-items: center;
    padding: 15px;
    transition: background-color 0.2s;
    &:hover {
      background-color: #eee;
    }
  }
`
const StyledNavDropdownText = styled.div`
  color: #000;
  line-height: 1.2em;
  strong {
    font-size: 1.2em;
    text-transform: uppercase;
    opacity: 0.8;
  }
  p {
    opacity: 0.6;
  }
`
const StyledNavDropdownIcon = styled.div`
  height: 36px;
  width: 36px;
  background-color: rgba(45, 200, 148, 0.26);
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: -2px;
  background-image: url(${props => props.img});
  background-size: contain;
`
const StyledNavDropdownTrigger = styled.li`
  perspective: 600px;
  &:hover ${StyledNavDropdown} {
    opacity: 1;
    transform: translate(-50%, 0) rotateX(0);
    visibility: visible;
    pointer-events: inherit;
  }
`
const StyledBurger = styled.div`
  position: relative;
  z-index: 100;
  cursor: pointer;
  padding: 20px;
  display: none;
  @media (max-width: 1000px) {
    display: inherit;
  }
  @media (max-width: 450px) {
    padding-right: 8px;
  }
  span {
    display: block;
    height: 2px;
    width: 26px;
    background-color: #fff;
    &:after,
    &:before {
      content: "";
      display: block;
      height: 2px;
      width: 30px;
      position: relative;
      background-color: #fff;
      right: 4px;
    }
    &:before {
      top: -8px;
    }
    &:after {
      top: 6px;
    }
  }
`

const StyledMobileNav = styled.div`
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: transform .3s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(30, 27, 58,.85);
  background-image: radial-gradient(transparent,#110e29),linear-gradient(to right,rgba(30,99,123,0.6),transparent);
  display: none;
  @media (max-width: 1000px) {
    display: flex;
  }
}
  &.closed {
    transform: translateY(-100%);
  }
  &.opened {
    transform: translateY(0);
  }
  li {
    list-style: none;
  a {
    font-size: 3em;
    font-weight: 900;
    color: #fff;
    @media (max-width: 600px) {
      font-size: 2.2em;
    }
  }
`
const StyledNavSocials = styled.div`
  display: flex;
  position: relative;
  margin-top: 50px;
  &:before {
    content: "";
    display: block;
    height: 1px;
    background-color: #fff;
    opacity: 0.4;
    width: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -30px;
  }
  a {
    display: block;
    height: 20px;
    width: 20px;
    background-size: contain;
    color: transparent !important;
    margin: 0 15px;
    &.facebook {
      background-image: url(${facebookIcon});
    }
    &.linkedin {
      background-image: url(${linkedinIcon});
    }
    &.instagram {
      background-image: url(${instagramIcon});
    }
  }
`

const StyledLangDiv = styled.div`
  display: flex;
	align-items: center;
  margin-left: 25px;

  button {
    background: transparent;
    border: none;
    color: white;
		font-size: 16px;
		&.active {
			text-decoration: underline;
		}
  }
`

const StyledDivider = styled.div`
  width: 1.5px;
  height: 15px;
  background-color: white;
	margin: auto 8px;
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  handleClick = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    })
  }
  setPolish = () => {
    i18next.changeLanguage("pl")
		localStorage.setItem("appLang", "pl");
  }
	setEnglish = () => {
    i18next.changeLanguage("en")
		localStorage.setItem("appLang", "en");
  }

  render() {
		const currentLang = this.props.i18n.language
    return (
      <StyledHeader>
        <GlobalContainer>
          <StyledNav>
            <StyledLogo
              data-sal="slide-down"
              data-sal-delay="600"
              data-sal-duration="400"
            >
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
              <StyledLangDiv>
                <button onClick={this.setPolish} className={`${currentLang === 'pl' ? 'active' : ''}`}>PL</button>
                <StyledDivider></StyledDivider>
                <button onClick={this.setEnglish} className={`${currentLang === 'en' ? 'active' : ''}`}>EN</button>
              </StyledLangDiv>
            </StyledLogo>
            <li
              data-sal="slide-down"
              data-sal-delay="700"
              data-sal-duration="400"
              className="desktop-nav"
            >
              <Link to="/" activeClassName="active">
                Leśna Hub
              </Link>
            </li>
            <li
              data-sal="slide-down"
              data-sal-delay="800"
              data-sal-duration="400"
              className="desktop-nav"
            >
              <Link to="/wydarzenia" activeClassName="active">
							{this.props.t('nav.events')}
              </Link>
            </li>
            <StyledNavDropdownTrigger
              data-sal="slide-down"
              data-sal-delay="900"
              data-sal-duration="400"
              className="desktop-nav"
            >
              <Link to="/oferta" activeClassName="active">
							{this.props.t('nav.offer')}
              </Link>
              <StyledNavDropdown>
                <Link to="/oferta/coworking">
                  <StyledNavDropdownIcon img={coworkImg} />
                  <StyledNavDropdownText>
                    <strong>Coworking</strong>
                    <p>{this.props.t('nav.cowokringSubtext')}</p>
                  </StyledNavDropdownText>
                </Link>
                <Link to="/oferta/sale-szkoleniowe">
                  <StyledNavDropdownIcon img={lectureImg} />
                  <StyledNavDropdownText>
                    <strong>{this.props.t('nav.trainingRoom')}</strong>
                    <p>{this.props.t('nav.trainingRoomSubtext')}</p>
                  </StyledNavDropdownText>
                </Link>
                <Link to="/oferta/wirtualne-biuro">
                  <StyledNavDropdownIcon img={virtualImg} />
                  <StyledNavDropdownText>
                    <strong>{this.props.t('nav.virtualOffice')}</strong>
                    <p>{this.props.t('nav.virtualOfficeSubtext')}</p>
                  </StyledNavDropdownText>
                </Link>
                <Link to="/oferta/biura-na-wynajem">
                  <StyledNavDropdownIcon img={officeImg} />
                  <StyledNavDropdownText>
                    <strong>{this.props.t('nav.officeRent')}</strong>
                    <p>{this.props.t('nav.officeRentSubtext')}</p>
                  </StyledNavDropdownText>
                </Link>
              </StyledNavDropdown>
            </StyledNavDropdownTrigger>
            <li
              data-sal="slide-down"
              data-sal-delay="1000"
              data-sal-duration="400"
              className="desktop-nav"
            >
              <Link to="/nasza-przestrzen" activeClassName="active">
							{this.props.t('nav.space')}
              </Link>
            </li>
            <li
              data-sal="slide-down"
              style={{ transitionDelay: "1.05s" }}
              data-sal-duration="400"
              className="desktop-nav"
            >
              <Link to="/blog" activeClassName="active">
                Blog
              </Link>
            </li>
            <li
              data-sal="slide-down"
              style={{ transitionDelay: "1.1s" }}
              data-sal-duration="400"
              className="desktop-nav"
            >
              <Link to="/kontakt" activeClassName="active">
							{this.props.t('nav.contact')}
              </Link>
            </li>
            <li
              data-sal="slide-down"
              data-sal-duration="400"
              style={{ transitionDelay: "1.2s" }}
              className="desktop-nav"
            >
              <StyledButton to="/rezerwacja">{this.props.t('nav.reservation')}</StyledButton>
            </li>
            <li>
              <StyledBurger
                onClick={this.handleClick}
                data-sal="slide-down"
                data-sal-delay="600"
                data-sal-duration="400"
              >
                <span />
              </StyledBurger>
            </li>
          </StyledNav>
          <StyledMobileNav
            className={this.state.isMenuOpen ? "opened" : "closed"}
          >
            <li
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="400"
            >
              <Link to="/">Leśna Hub</Link>
            </li>
            <li
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="400"
            >
              <Link to="/oferta/coworking">Coworking</Link>
            </li>
            <li
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="400"
            >
              <Link to="/oferta/sale-szkoleniowe">{this.props.t('nav.trainingRoom')}</Link>
            </li>
            <li
              data-sal="slide-right"
              data-sal-delay="400"
              data-sal-duration="400"
            >
              <Link to="/oferta/wirtualne-biuro">{this.props.t('nav.virtualOffice')}</Link>
            </li>
            <li
              data-sal="slide-right"
              data-sal-delay="500"
              data-sal-duration="400"
            >
              <Link to="/nasza-przestrzen">{this.props.t('nav.space')}</Link>
            </li>
            <li
              data-sal="slide-right"
              data-sal-delay="600"
              data-sal-duration="400"
            >
              <Link to="/kontakt">{this.props.t('nav.contact')}</Link>
            </li>
            <li
              data-sal="slide-right"
              data-sal-delay="700"
              data-sal-duration="400"
            >
              <Link to="/rezerwacja">{this.props.t('nav.reservation')}</Link>
            </li>
            <li>
              <StyledNavSocials>
                <a
                  className="facebook"
                  href="https://www.facebook.com/lesnahub/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  fb
                </a>
                <a
                  className="instagram"
                  href="https://www.instagram.com/lesnahub/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  in
                </a>
                <a
                  className="linkedin"
                  href="https://www.linkedin.com/company/le%C5%9Bna-hub/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  li
                </a>
              </StyledNavSocials>
            </li>
          </StyledMobileNav>
        </GlobalContainer>
      </StyledHeader>
    )
  }
}

export default withTranslation()(Header)
