import React from "react"
import { Link } from 'gatsby'
import { GlobalContainer, GlobalSection } from "./GlobalStyle"
import styled from "styled-components"
import footerImg from "../images/footer-bg.jpg"
import logo from "../images/logo.png"
import facebookIcon from "../images/icons/facebook.svg"
import instagramIcon from "../images/icons/instagram.svg"
import linkedinIcon from "../images/icons/linkedin.svg"
import { useTranslation} from 'gatsby-plugin-react-i18next';

const StyledFooterColumns = styled.div`
  display: flex;
  padding-top: 120px;
  color: #fff;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  div {
    flex: 1;
    padding-left: 50px;
    img {
      width: 150px;
      height: 56px;
    }
    @media (max-width: 1200px) {
      img {
        width: 110px;
        height: 41px;
      }
    }
    @media (max-width: 1000px) {
      padding-left: 0;
      margin-bottom: 50px;
    }
    div {
      font-size: 1.2em;
      color: var(--main);
      margin-bottom: 20px;
    }
    a {
      color: #fff;
    }
  }
  div:first-of-type {
    flex: 2;
    padding-left: 0;
    p {
      opacity: .7;
      margin: 20px 0;
    }
  }
  div:last-of-type {
    ul {
      display: flex;
      li {
        padding: 0 10px;
      }
    }
  }
`
const StyledFooterFooter = styled.div`
  color: #fff;
  border-top: 1px solid rgba(255,255,255,0.1);
  padding: 20px 0;
  margin-top: 80px;
  & > div {
    display: flex;
    @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    }
  }
  a {
    color: #fff;
    opacity: .7;
    display: inline-block;
    margin-left: 5px;
  }
 `
const StyledFooterWrap = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    //background-image: url(${footerImg});
    background-color: #1e1b3a;
    background-position: 0 -300px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-image: radial-gradient(transparent,#110e29),linear-gradient(to right,rgba(30, 99, 123, 0.6),transparent);
  }
`
const StyledFooterSocials = styled.ul`
  a {
    display: block;
    height: 20px;
    width: 20px;
    background-size: contain;
    color: transparent !important;
    &.facebook {
      background-image: url(${facebookIcon});
    }
    &.linkedin {
      background-image: url(${linkedinIcon});
    }
    &.instagram {
      background-image: url(${instagramIcon});
    }
  }
`


const Footer = () => {
	const { t } = useTranslation()

	return ( <StyledFooterWrap>
    <GlobalSection bg="transparent" noPadding>
      <GlobalContainer>
        <StyledFooterColumns>
          <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="400">
            <img src={ logo } alt="logo"/>
            <p>The spirit of coworking allows you to find coworkers who are worth working with.</p>
            <i>-Cynthia Chiam</i>
          </div>
          <div data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
            <div>Menu</div>
            <ul>
              <li><Link to="/">{t('footer.homepage')}</Link></li>
              <li><Link to="/wydarzenia">{t('nav.events')}</Link></li>
              <li><Link to="/kontakt">{t('nav.contact')}</Link></li>
              <li><Link to="/rezerwacja">{t('nav.reservation')}</Link></li>
            </ul>
          </div>
          <div data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">
            <div>{t('nav.offer')}</div>
            <ul>
              <li><Link to="/oferta/sale-szkoleniowe">{t('nav.trainingRoom')}</Link></li>
              <li><Link to="/oferta/coworking">Coworking</Link></li>
              <li><Link to="/oferta/wirtualne-biuro">{t('nav.virtualOffice')}</Link></li>
            </ul>
          </div>
          <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="400">
            <div>{t('footer.follow')}</div>
            <StyledFooterSocials>
              <li><a className="facebook" href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">fb</a></li>
              <li><a className="instagram" href="https://www.instagram.com/lesnahub/" target="_blank" rel="noopener noreferrer">in</a></li>
              <li><a className="linkedin" href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank" rel="noopener noreferrer">li</a></li>
            </StyledFooterSocials>
          </div>
        </StyledFooterColumns>
      </GlobalContainer>
      <StyledFooterFooter>
        <GlobalContainer>
          <div>Leśna1Hub © {new Date().getFullYear()}. All rights reserved.</div>
          <Link to="/polityka-prywatnosci">{t('footer.policy')}</Link>
        </GlobalContainer>
      </StyledFooterFooter>
    </GlobalSection>
  </StyledFooterWrap>
	
)
	}
export default Footer
