import React, { Component } from "react";
import styled from 'styled-components';
import modalBg from '../images/promo-bg.png';
import { Button } from "./GlobalStyle"
import Image from "./Image"

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 1000000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0,.75);
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalContent = styled.div`
  padding: 50px;
  height: 560px;
  width: 938px;
  font-size: 100px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  @media(max-width: 850px) {
    width: 95%;
    margin: 0 auto;
    height: 500px;
  }
  @media(max-width: 550px) {
    height: 350px;
  }
  .gatsby-image-wrapper {
    min-height: 600px;
    min-width: 930px;
    width: 100%;
    position: absolute !important;
    top: -60px;
    left: -16px;
    @media(max-width: 850px) {
      min-width: 100%;    
      min-height: 500px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
    @media(max-width: 550px) {
      min-height: 350px;
      height: 100%;
    }
  }
  a {
    padding: 10px;  
    position: relative;
    @media(max-width: 550px) {
      top: 35px;
    }
  }
  &::after {
    content: '';
    display: block;
    height: 600px;
    width: 930px;
    background-image: linear-gradient(45deg,#2EC796,#A49AFE );
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: -1;
    @media(max-width: 850px) {
      display: none;      
    }
`
const ModalClose = styled.div`
  position: absolute;
  top: 30px;
  right: 35px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  &:after, &:before {
    content: '';
    display: block;
    height: 1px;
    width: 20px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    transform: rotate(45deg)
  }
  &:before {
    transform: rotate(-45deg)
  }
`

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  checkIfModalWasClosed = () => {
    return sessionStorage.getItem('wasModalClosed');
  }

  handleModalClose = () => {
    this.setState({isVisible: false})
    sessionStorage.setItem('wasModalClosed', 'true');
  }
  handleModalOpen = () => {
    if(this.checkIfModalWasClosed() !== 'true') {
      this.setState({ isVisible: true })
    }
  }
  handleUserScroll = () => {
    if(this.checkIfModalWasClosed() !== 'true'){
      const scrollPosition = window.scrollY;
      if (scrollPosition > 200) {
        this.setState({isVisible: true})
        sessionStorage.setItem('wasModalClosed', 'true');
      };
    }
  }
  async componentDidMount() {
    window.addEventListener('scroll', this.handleUserScroll);
    document.addEventListener('mouseleave', this.handleModalOpen);
  }

  render() {
    const {isVisible} = this.state;
    return (
      <>
        {isVisible && (
          <ModalOverlay onClick={this.handleModalClose}>
            <ModalContent>
              <Image filename='lesna-30.png' alt="modal background"/>
              <ModalClose onClick={this.handleModalClose}/>
              <Button to="/rezerwacja?utm_source=modal&utm_medium=modal&utm_campaign=summer_sale">Napisz do nas</Button>
            </ModalContent>
          </ModalOverlay>
        )}
      </>
    )
  }
}

export default Modal
