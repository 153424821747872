import React from "react"
import { Link } from "gatsby"
import Transition from '../components/transition'
import { GlobalStyle } from "../components/GlobalStyle"
import Header from "../components/header"
import Footer from "../components/Footer"
import CookieBanner from "react-cookie-banner"
import styled, { keyframes } from "styled-components"
import cookiesImg from '../images/icons/cookies.svg'
import "../static/assets/animate.min.css"
import "../static/assets/typography.css"
import Modal from "../components/Modal"
import Helmet from "react-helmet"
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_pl from "../../src/locales/pl/translations.json";
import common_en from "../../src/locales/en/translations.json";
//import BlackFridayModal from "../components/BlackFridayModal"

const CookieBarStyles = {
  banner: {
    backgroundColor: '#eee',
    width: '550px',
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '50px 30px 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: "-2px -2px 4px 0 rgba(12,0,46,.04)",
    height: 'auto'
  },
  button: {
    border: '1px solid #000',
    borderRadius: 50,
    padding: '4px 30px',
    position: 'static',
    lineHeight: '32px',
    background: 'transparent',
    color: '#000',
    fontSize: '18px',
    fontWeight: 600,
    opacity: 1,
    marginTop: -18
  },
  message: {
    padding: '9px 67px',
    lineHeight: 1.3,
    textAlign: 'center',
    margin: '50px 0',
    color: '#000'
  },
  link: {
    textDecoration: 'none',
    marginTop: '20px',
    color: '#000',
    fontWeight: 'bold'
  }
}

const cookieSlideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translate(0);
  }
`

const StyledCookieBar = styled.div`
  .cookie-message:before {
    content: 'Nom. Nom. Nom...';
    display: block;
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 50px;
    height: 20px;
    width: 100%;
    color: var(--main);
    @media (max-width: 400px) {
      font-size: 26px;
    }
  }

  .react-cookie-banner:before {
    content: '';
    display: block;
    height: 120px;
    width: 120px;
    background-image: url(${cookiesImg});
    background-size: contain;
    @media (max-width: 650px) {
      height: 100px;
      width: 100px;
    }
    @media (max-width: 400px) {
      height: 64px;
      width: 64px;
    }
  }
  .react-cookie-banner {
    transform: translateX(100%);
    animation: .4s 3s ${cookieSlideIn} ease-out forwards;
    @media (max-width: 650px) {
      width: 100% !important;
      .cookie-message {
        padding: 10px 0 !important;
      }
    }
    @media (max-width: 400px) {
      padding: 40px 15px 40px !important;
    }
  }
`
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

let appLang

if (typeof window !== 'undefined') { 
	appLang = localStorage?.getItem("appLang");
}

i18next.init({
	returnNull: false,
	interpolation: { escapeValue: false },
	lng: appLang || "pl",
	resources: {
		pl: {
			translation: common_pl,
		},
		en: {
			translation: common_en,
		},
	},
});

const Layout = ({ children, location }) => (
  <>
    <div>
      {/*<Modal />*/}
      {/*<BlackFridayModal />*/}
			<I18nextProvider i18n={i18next}>
      <Transition location = {location}>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-7NCCX78XK5" ></script>
          <script>
            {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-7NCCX78XK5'); `}
          </script>
          <script type="application/ld+json">
            {`
           {
                  "@context": "https://schema.org",
                  "@type": "LocalBusiness",
                  "name": "LesnaHub - Coworking",
                  "image": "",
                  "@id": "",
                  "url": "https://lesnahub.pl/",
                  "telephone": "+48603113673",
                  "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Lesna 1",
                  "addressLocality": "Sopot",
                  "postalCode": "81-854",
                  "addressCountry": "PL"
              },
                  "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 54.4300116,
                  "longitude": 18.5612722
              },
                  "openingHoursSpecification": {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                  ],
                  "opens": "09:00",
                  "closes": "18:00"
              },
                  "sameAs": [
                  "https://www.facebook.com/lesnahub/",
                  "https://www.instagram.com/lesnahub/"
                  ]
              }
          `}
          </script>
        </Helmet>
        <GlobalStyle />
        <Header />
        {children}
        <Footer />
        <StyledCookieBar>
          <CookieBanner
            styles={CookieBarStyles}
            message="Leśna Hub korzysta z plików cookies, w celu usprawnienia działania serwisu."
            link={<Link to="/polityka-prywatnosci">Więcej</Link>}
            buttonMessage='Akceptuję'
            dismissOnScroll={false}
            onAccept={() => {}}
          />
        </StyledCookieBar>
      </Transition>
			</I18nextProvider>
    </div>
  </>
)
export default Layout
