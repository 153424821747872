// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-post-js": () => import("./../../../src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasza-przestrzen-js": () => import("./../../../src/pages/nasza-przestrzen.js" /* webpackChunkName: "component---src-pages-nasza-przestrzen-js" */),
  "component---src-pages-oferta-biura-na-wynajem-js": () => import("./../../../src/pages/oferta/biura-na-wynajem.js" /* webpackChunkName: "component---src-pages-oferta-biura-na-wynajem-js" */),
  "component---src-pages-oferta-coworking-js": () => import("./../../../src/pages/oferta/coworking.js" /* webpackChunkName: "component---src-pages-oferta-coworking-js" */),
  "component---src-pages-oferta-index-js": () => import("./../../../src/pages/oferta/index.js" /* webpackChunkName: "component---src-pages-oferta-index-js" */),
  "component---src-pages-oferta-sale-szkoleniowe-js": () => import("./../../../src/pages/oferta/sale-szkoleniowe.js" /* webpackChunkName: "component---src-pages-oferta-sale-szkoleniowe-js" */),
  "component---src-pages-oferta-wirtualne-biuro-js": () => import("./../../../src/pages/oferta/wirtualne-biuro.js" /* webpackChunkName: "component---src-pages-oferta-wirtualne-biuro-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-rezerwacja-js": () => import("./../../../src/pages/rezerwacja.js" /* webpackChunkName: "component---src-pages-rezerwacja-js" */),
  "component---src-pages-wydarzenia-js": () => import("./../../../src/pages/wydarzenia.js" /* webpackChunkName: "component---src-pages-wydarzenia-js" */)
}

